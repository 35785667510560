<script setup lang="ts">
const year = new Date().getFullYear()
</script>

<template lang="pug">
footer.py-8.bg-slate-700.text-slate-50
  .container.mx-auto.px-3
    .flex.gap-12.justify-between.flex-col(class="md:flex-row")
      div
        img(
          src="/images/logo-white.svg"
          alt="Zulassung Digital"
          style="height: 30px; width: auto;"
          loading="lazy"
        )
</template>
